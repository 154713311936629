.blur-bg {
    position: relative;
    overflow: hidden;

    &:after {
        content: none;
        display: block;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background-repeat: no-repeat;
        background-position: top center;
        background-attachment: fixed;
        background-size: cover;
        position: absolute;
        top: -10px;
        left: -10px;
        right: 0;
        bottom: 0;
        z-index: -1;
        filter: blur(5px);

        @include mq('tablet') {
            content: " ";
        }
    }
}

#wrapper--bg-1 .blur-bg:after {
    background-image: linear-gradient(rgba(color('primary-dark-1'), 0.4), rgba(color('primary-dark-1'),0.4)), url(../img/hero-1.jpg);
}

#wrapper--bg-2 .blur-bg:after {
    background-image: linear-gradient(rgba(color('primary-dark-1'), 0.4), rgba(color('primary-dark-1'),0.4)), url(../img/hero-2.jpg);
}