
// color pallete
$color-pallete : ("primary": #22448D,
"primary-lightest": #D2D9E7,
"primary-dark-1": #00184D,
"primary-dark-2": #112246,
"red": #D0100f,
"red-dark": #670807,
"gray": #CCCCCC,
"gray-dark-1": #737373,
"gray-dark-2": #777777,
"black": #000000,
"black-light": #333333,
"white": #ffffff
);

@function color($key: 'primary') {
    @return map-get($color-pallete, $key);
}

// EXAMPLE
// button-color: color('primary'); 


// font-family
$ff-primary: 'source-hans-sans-jp','ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif;