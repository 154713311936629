// Variables
$hamburger-line-width--sm: 22px;
$hamburger-line-width: 30px;
$hamburger-line-width--lg: 45px;


.nav {
    background: color('primary');
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: 1;
    padding: 20px;
    font-size: 18px;
    width: 100%;
    max-height: 0;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: opacity 0.3s;

    @include mq('tablet') {
        width: auto;
        flex-direction: row;
        position: relative;
        z-index: 0;
        padding: 0 15px;
        flex: 1 1 auto;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        visibility: visible;
        overflow: initial;
        opacity: 1;
        max-height: none;
    }

    @include mq('tablet-port') {
        font-size: 16px;
        flex: 1 1 70%;
    }

    @include mq('desktop') {
        flex: 1 1 calc(100% - 500px);
        padding: 0 20px;
    }

    // opened state
    &.is-opened {
        visibility: visible;
        z-index: 1;
        opacity: 1;
        overflow: auto;
        max-height: none;
        transition: opacity 0.3s;
        z-index: 50;
    }

    &:before {
        content: none;
        position: absolute;
        top: 0;
        right: calc(100% - 17px);
        height: 100%;
        width: 30px;
        background: color('primary');
        transform: skewX(-20deg);

        @include mq('tablet') {
            content: '';
        }

        @include mq('tablet-wide') {
            right: calc(100% - 20px);
            width: 40px;
        }
    }

    &__list {
        display: flex;
    }

    &__item {
        &:not(:last-child) {
            margin: 0 0 10px 0;

            @include mq('tablet') {
                margin: 0 15px 0 0;
            }
        }
    }

    &__link {

        &:link,
        &:visited {
            color: color('white');
            font-weight: 300;
            transition: all 0.3s;
        }

        &:hover {
            font-weight: 700;
            text-decoration: none;
        }
    }

    &__item.is-active &__link {
        font-weight: 700;
    }
}

//hamburger
.nav-toggle {

    &:link,
    &:visited {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: color('primary');
        color: color('white');
        text-decoration: none;
        width: 50px;
        cursor: pointer;
        text-align: center;

        @include mq('phone') {
            width: 75px;
        }

        @include mq('phablet') {
            width: 100px;
        }
    }

    span {
        position: relative;
        display: block;
        height: 4px;
        width: $hamburger-line-width--sm;
        margin-top: 8px;
        border-radius: 4px;
        background-color: color('white');
        // transition: all .25s;

        @include mq('phone') {
            width: $hamburger-line-width;
            margin-top: 10px;
        }

        @include mq('phablet') {
            width: $hamburger-line-width--lg;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            height: 4px;
            width: $hamburger-line-width--sm;
            border-radius: 4px;
            background-color: color('white');
            // transition: top .15s ease-out, bottom .15s ease-out, transform 0.3s ease-in;

            @include mq('phone') {
                width: $hamburger-line-width;
            }

            @include mq('phablet') {
                width: $hamburger-line-width--lg;
            }
        }

        &:before {
            top: -8px;

            @include mq('phablet') {
                top: -12px;
            }
        }

        &:after {
            bottom: -8px;

            @include mq('phablet') {
                bottom: -12px;
            }
        }
    }

    small {
        font-size: 10px;
        text-transform: capitalize;
        line-height: 1;
        margin-top: 15px;

        @include mq('phablet') {
            font-size: 13px;
            margin-top: 22px;
        }
    }

    // open state
    &.is-opened {
        background-color: color('white');
        color: color('primary');

        span {
            background: transparent;

            &:before,
            &:after {
                background-color: color('primary');
                top: 0;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}