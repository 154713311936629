.big-heading {
    margin-top: 3rem;

    @include mq('tablet') {
        margin-top: 5rem;
    }

    h1 {
        line-height: 1.5;

        @include mq('tablet') {
            line-height: 55px;
        }
    }
}

.cta {
    &-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 770px;
        max-height: 140px;
        text-align: center;
        align-items: center;
        justify-content: center;
        border: 1px solid #707070;
        margin: 2.5rem auto 3.5rem auto;

        &,
        &:hover,
        &:active,
        &:link,
        &:focus {
            text-decoration: none;
        }

        @include mq('tablet') {
            margin: 3rem auto 5rem auto;
        }

        .cta-img {
            flex: 0 0 150px;
            max-width: 150px;

            @include mq('tablet') {
                flex: 0 0 200px;
                max-width: 200px;
            }

            justify-self: flex-start;
        }

        img {
            max-height: 138px;
        }

        box-shadow: 0 11px 0 -6px color('gray');
    }

    &-content {
        flex: 0 0 calc(100% - 150px);
        max-width: calc(100% - 150px);

        @include mq('tablet') {
            flex: 0 0 calc(100% - 200px);
            max-width: calc(100% - 200px);
        }

        &__gray,
        &__blue {
            font-weight: 500;
        }

        &__gray {
            font-size: 14px;
            line-height: 16px;
            color: color('gray-dark-2');

            @include mq('tablet') {
                line-height: 35px;
                font-size: 30px;
            }
        }

        &__blue {
            font-size: 18px;
            line-height: 20px;
            color: color('primary');

            @include mq('tablet') {
                font-size: 40px;
                line-height: 45px;
            }
        }
    }
}

.recruit {
    &-column {
        margin-bottom: 5rem;

        // h2 {
        //     font-size: 30px;
        //     position: relative;
        //     margin-bottom: 30px;
        //     padding-left: 1.2rem;
        //     color: color('black');

        //     &:before {
        //         left: 0;
        //         width: 4px;
        //         top: .25rem;
        //         content: '';
        //         height: 30px;
        //         position: absolute;
        //         background-size: contain;
        //         background-repeat: no-repeat;
        //         background-image: url(../img/bullet-h2@2x.png);
        //     }
        // }

    }

    &-items {
        padding-top: 20px;
        padding-bottom: 40px;

        @include mq('tablet') {
            padding-top: 50px;
            padding-bottom: 70px;
        }
    }

    &-item {
        text-align: center;

        img {
            box-shadow: 0 11px 0 -6px color('gray');
        }

        &__content {
            color: color('black-light');

            h4 {
                font-size: 18px;

                @include mq('tablet') {
                    font-size: 21px;
                }
            }

            p {
                font-size: 14px;

                @include mq('tablet') {
                    font-size: 16px;
                }
            }

            padding-top: 30px;
            padding-bottom: 35px;
        }
    }
}