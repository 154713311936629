#message__heading {
	margin: 80px auto 72px;

	& .message__title {
		font-size: 50px;
		font-weight: 500;
		color: color('primary');
	}
}

@include mq('tablet-port', 'max') {
	#message__heading {
		margin: 20px auto 24px;

		& .message__title {
			font-size: 30px;
		}
	}

	.director__name__position {
		display: flex;
		flex-direction: column-reverse;
	}

	.directors__message {
		flex-direction: column !important;
		align-items: center;

		& .representative__director {
			display: flex;
			flex-direction: column;
			align-items: center;

			& .representative__director__image {
				margin-bottom: 25px;
			}
		}

		& .message__paragraph {
			margin: 30px 0px 51px !important;
		}
	}

	.directors__position span {
		display: none;
	}
}

.directors__message {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	& .representative__director__image {

		margin-bottom: 25px;

		@include mq('phone') {
			width: 370px;
		}
	}

	h3 {
		font-size: 18px;

		@include mq('tablet') {
			font-size: 24px;
		}
	}

	& p {
		font-weight: 500;
		font-size: 14px;

		@include mq('tablet') {
			font-size: 16px;
		}
	}

	& .message__paragraph {
		width: 570px;
		max-width: 100%;
		margin-right: 30px;
		margin-bottom: 150px;
		margin: 0px 30px 150px 0px;

		& p {
			font-weight: 500;
			font-size: 14px;

			@include mq('tablet') {
				font-size: 16px;
			}
		}
	}
}