
#privacy-policy__body {
  margin-top: 80px;
  margin-bottom: 125px;

  & p,
  & ul {
    font-weight: 500;
  }
}

.privacy-policy__title {
  margin-bottom: 30px;
}

.privacy-policy__head {
  display: flex;
  align-items: center;

  & img {
    height: 23px;
    margin-right: 15px;
  }

  & h2 {
    font-weight: 700;
    color: #000;
    margin-bottom: 1.8rem;
  }
}

.privacy-policy__info h3 {
  font-weight: 700;
}

.personal-info__to__third-party ul {
  list-style-type: none;
  padding: 0 0 0 13px;
  font-weight: 500;

  & li {
    position: relative;

    &:before {
      content: '';
      width: 3.5px;
      height: 3.5px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: -8px;
      background: color('black');
    }
  }
}

.personal-info {
  margin: 21px auto;
}

.privacy-policy__info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: color("gray-dark-2");

  & img {
    height: 20px;
    margin-right: 10px;
  }
}

@include mq("tablet", "max") {
  #privacy-policy__body {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .privacy-policy__head h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .privacy-policy__info h3 {
    font-size: 16px;
  }

  #privacy-policy__body p,
  #privacy-policy__body ul {
    font-size: 14px;
  }

  .privacy-policy__head,
  .privacy-policy__info {
    align-items: end;
  }

  .personal-info {
    margin: 30px 0;
  }

  .contact__us {
    padding-bottom: 50px;
    border-bottom: 1px solid color('gray');
  }
}