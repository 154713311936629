//service air
.service-air {
    margin: 20px 0 40px 0;

    @include mq('tablet') {
        margin: 90px 0;
    }

    &__title {
        color: color('primary');
        line-height: 1.5;
    }

    &__desc {
        font-size: 16px;
        color: color('gray-dark-2');
        margin: 20px 0 25px;

        @include mq('tablet') {
            font-size: 24px;
            margin: 35px 0 42px;
        }
    }

    &__img-wrapper {
        margin: 0 auto 30px;
        width: 70%;

        @include mq('phablet') {
            width: auto;
        }

        @include mq('tablet') {
            margin-bottom: 30px;
        }

        &--no-mb {
            margin-bottom: 0 !important;
        }
    }

}

//service section
.s-section {
    margin-bottom: 50px;
}

// service air needs
.s-needs {

    &__row {
        margin-top: 25px;
    }

    // service needs card
    &-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        color: color('primary');
        border: 2px solid color('primary');
        border-radius: 6px;
        overflow: hidden;
        margin: 0 auto 30px;
        width: 80%;

        @include mq('phablet'){
            width: 90%;
            flex-direction: row;
            font-size: 20px;
        }

        @include mq('tablet-small'){
            width: 80%;
        }

        @include mq('bootstrap-lg'){
            width: auto;
            margin: 0;
        }

        @include mq('desktop'){
            font-size: 24px;
        }

        &__img {

            @include mq('phablet'){
                flex: 0 1 180px;
            }

            @include mq('desktop'){
                flex: 0 1 220px;
            }
        }

        &__text {
            padding: 10px 8px;

            @include mq('phablet'){
                padding: 0;
                flex: 0 1 calc(100% - 180px);
            }

            @include mq('desktop'){
                flex: 0 1 calc(100% - 220px);
            }
        }
    }
}


// service case study
.s-case-study {
    font-weight: 500;

    &__row {
        margin-top: 25px;
    }

    &__info {
        display: flex;
        flex-direction: column;

        @include mq('phablet') {
            flex-direction: row;
        }

        &:first-child {
            margin-top: 30px;

            @include mq('tablet') {
                margin-top: 0;
            }
        }

        &:not(:last-child) {
            margin-bottom: 15px;

            @include mq('phablet') {
                margin-bottom: 30px;
            }
        }
    }

    &__label {
        font-size: 14px;
        height: 24px;
        width: 110px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: color('gray-dark-2');
        color: color('white');
        border-radius: 10px;
        margin-right: 15px;

        @include mq('phablet') {
            height: 35px;
            width: 130px;
            min-width: 130px;
            font-size: 18px;
        }
    }

    &__value {
        padding-top: 10px;
        font-size: 14px;

        @include mq('phablet') {
            padding-top: 5px;
            font-size: 16px;
        }
    }

    &__item {
        position: relative;
        padding-left: 10px;

        &:before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: color('black');
        }
    }
}


//service voice
.s-voice {
    &__row {
        margin-top: 25px;
    }

    &-content {
        margin-top: 25px;

        @include mq('tablet') {
           margin-top: 0;
        }

        &__desc {
            margin-top: 15px;
            font-size: 14px;

            @include mq('tablet') {
                margin-top: 30px;
                font-size: 16px;
             }
        }
    }
}