.banner {
    height: 70px;
    background-size: cover;
    position: relative;

    @include mq('phablet') {
        height: 150px;
    }

    @include mq('tablet') {
        height: 240px;
        margin-top: 10px;
    }

    .banner__title {
        width: 100%;
        height: 25px;
        line-height: 1;
        text-align: center;
        color: color('white');
        margin: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        font-weight: 700;
        font-size: 16px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq('phablet') {
            height: 50px;
            font-size: 28px;
        }

        @include mq('tablet') {
            height: 80px;
            font-size: 40px;
        }

        &:before {
            background-size: cover;
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            filter: blur(3px);
            height: 70px;
            width: 100%;
            z-index: -1;

            @include mq('phablet') {
                height: 150px;
            }

            @include mq('tablet') {
                height: 240px;
            }
            
        }
    }

    &--whatsnew {

        & {
            background-image: url("../img/whatsnew-header.jpg");
        }

        & .banner__title:before {
            background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url("../img/whatsnew-header.jpg");

        }
    }

    &--inquiry {

        & {
            background-image: url("../img/inquiry-header-bg.jpg");
        }

        & .banner__title:before {
            background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url("../img/inquiry-header-bg.jpg");

        }
    }

    &--recruit {

        & {
            background-image: url("../img/recruit-header-bg.jpg");
        }

        & .banner__title:before {
            background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url("../img/recruit-header-bg.jpg");

        }
    }

    &--facility {

        & {
            background-image: url("../img/facility-header-bg.png");
        }

        & .banner__title:before {
            background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url("../img/facility-header-bg.png");

        }
    }

    &--service {

        & {
            background-image: url("../img/service-header-bg.jpg");
        }

        & .banner__title:before {
            background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url("../img/service-header-bg.jpg");

        }
    }

    &--about {

        & {
            background-image: url("../img/about-header.jpg");
        }

        & .banner__title:before {
            background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url("../img/about-header.jpg");

        }
    }
}