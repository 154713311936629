.header {
    display: flex;
    justify-content: space-between;
    background: transparent;
    position: relative;
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: 1000px 0 0 0 color('primary'), -1000px 0 0 0 color('white');

    &__logo {
        display: inline-block;
        background: color('white');
        flex: 0 1 calc(100% - 40px); 
        padding: 10px 15px;

        @include mq('tablet') {
            flex: 0 1 210px;
            padding: 20px 30px 20px 10px;
        }

        @include mq('tablet-port') {
            flex: 0 1 30%;
            padding: 20px 50px 20px 10px;
        }

        @include mq('desktop') {
            flex: 0 1 500px;
            padding: 20px 50px 20px 20px;
        }
    }

    &__contact {
        position: absolute;
        top: calc(100% - 10px);
        right: 50px;
        z-index: 2;

        @include mq('tablet-wide') {
            top: calc(100% - 15px);
        }

        @include mq('desktop') {
            top: calc(100% - 20px);
        }
    }
}