
body {
    background: color('white');
    color: color('black-light');
    font-family: $ff-primary;
    font-size: 16px;
    font-weight: 400;
    font-weight: normal;
    line-height: 1.5;
}

.container {
    max-width: 1200px;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

p {
    margin-bottom: 0;
}

#preload {
    display: none;
}