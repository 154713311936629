// #hero
.hero {
    height: 510px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 15px 0 35px;

    .container {
        height: 100%;
    }

    @include mq('phablet') {
        height: 700px;
        padding: 30px 0 35px;
    }

    @include mq('tablet') {
        height: 530px;
        padding: 120px 0 35px;
        margin-right: 55px;
    }

    @include mq('desktop') {
        margin-right: 0;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        @include mq('tablet') {
            flex-direction: row;
            justify-content: flex-end;
        }
    }

    &__contact {
        margin: 0 -15px;
    }

    &__box {
        background: rgba(color('black'), 0.6);
        color: color('white');
        padding: 10px;
        border: 2px solid color('white');
        position: relative;
        font-weight: 500;
        align-self: flex-end;
        margin: 0;

        @include fluid-type(480px, 768px, 21px, 50px);

        @include mq('tablet') {
            padding: 25px 33px;
            width: 370px;
            background: rgba(color('black'), 0.3);
        }

        span {
            text-align: justify;
            display: block;
            line-height: 1.6;

            &:nth-child(2),
            &:last-child {
                letter-spacing: 5px;

                @include mq('tablet') {
                    letter-spacing: 10px;
                }
            }
        }
    }
}

#hero--bg-1 {
    background-image: url(../img/hero-1-sm.jpg);

    @include mq('tablet') {
        background-image: none;
    }
}

#hero--bg-2 {
    background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'), 0.3)), url(../img/hero-2-sm.jpg);

    @include mq('tablet') {
        background-image: none;
    }
}

// #INFO
.info {
    background: rgba(color('white'), 0.6);
    padding: 25px 0 0;

    @include mq('tablet') {
        padding: 25px 0 35px;
    }

    &__row {
        flex-wrap: wrap-reverse;

        @include mq('desktop') {
            margin: 0 -38px;

            &>[class^="col"] {
                padding: 0 38px;
            }
        }
    }
}



// #NOTICE
.notice {
    color: color('primary');
    background: color('primary-lightest');
    margin: 0 -15px;
    padding: 20px 15px;

    @include mq('tablet') {
        background: transparent;
    }

    &__title {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__list {
        font-size: 14px;
        font-weight: 300;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    &__link {

        &,
        &:link,
        &:visited {
            display: block;
            color: (color('primary'));
            text-decoration: none;
            display: flex;
            flex-direction: column;

            @include mq('tablet') {
                flex-direction: row;
            }
        }
    }

    &__date {
        font-weight: 700;
        margin: 0 0 5px 0;

        @include mq('tablet') {
            margin: 0 20px 0 0;
        }

    }
}

// #INFO-DETAILS
.info-details {
    color: color('primary');
    margin-bottom: 40px;

    @include mq('tablet') {
        margin-bottom: 0;
    }

    &__title {
        margin-bottom: 20px;
        text-shadow: 1px 2px color('white');
        text-align: center;
        line-height: 1.4;
        font-weight: 700;
        @include fluid-type(480px, 768px, 24px, 30px);

        @include mq('phablet') {
            text-align: left;
        }
    }

    &__content {
        @include fluid-type(480px, 768px, 16px, 18px);
    }
}