.scroll-to-top {

    &,
    &:link,
    &:visited {
        position: fixed;
        bottom: 65px;
        right: 15px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: color('primary');
        border: 1px solid color('white');
        border-radius: 4px;
        transition: all 0.3s;
        z-index: 100;

        @include mq('tablet') {
            bottom: 70px;
            width: 27px;
            height: 27px;
            right: 20px;
        }
    }

    &:focus {
        outline: none;
    }

    &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid color('white');

        @include mq('tablet') {
            border-left-width: 8px;
            border-right-width: 8px;
            border-bottom-width: 8px;
        }
    }
}