
.n-notice {
    margin: 32px 0;

    @include mq('phablet') {
        margin: 60px 0;
    }

    @include mq('tablet') {
        margin: 100px 0;
    }

    &__wrapper {

        @include mq('tablet') {
            width: 85%;
            margin: 0 auto;
        }
    }

    &__item {
        border-bottom: 1px solid color('gray');

        &:first-child {
            border-top: 1px solid color('gray');
        }
    }

    &__link {

        &,
        &:link,
        &:visited {
            display: flex;
            flex-direction: column;
            padding: 5px;
            text-decoration: none;
            color: color('black-light');

            @include mq('phablet') {
                flex-direction: row;
                padding: 10px 5px;
            }
        }

        &:hover {
            background: color('primary');
            color: color('white');
        }
    }

    &__date {
        font-weight: 700;
        min-width: 160px;
        margin-bottom: 5px;

        @include mq('phablet') {
            margin-bottom: 0;
        }
    }

    &___text {
        font-size: 14px; 

        @include mq('phablet') {
            font-size: 16px; 
        }
    }

}


// notice pagination
.n-notice-pagination {
    margin-top: 40px;
    padding-bottom: 50px;
    border-bottom: 1px solid color('gray');

    @include mq('tablet') {
        border-bottom: 0;
        margin-top: 50px;
    }

    &__list {
        display: flex;
        justify-content: center;
    }

    &__item {

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__link {

        &,
        &:link,
        &:visited {
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            font-weight: 500;
            font-size: 14px;
            border-radius: 50%;
            background: color('white');
            color: color('primary');
            box-shadow: 0 3px 0 0 color('primary-lightest');
            text-decoration: none;
            transition: all 0.2s;

            @include mq('phablet') {
                height: 35px;
                width: 35px;
                font-size: 16px;
            }
        }

        &.is-active,
        &:hover {
            color: color('white');
            background: color('primary');
        }

        &:active {
            box-shadow: 0 1px 0 0 color('primary-lightest');
        }
    }
}