.contact {

    &:link,
    &:hover,
    &:visited {
        display: block;
        background: color('white');
        color: color('primary');
        font-weight: 700;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        padding: 10px;

        @include mq('phone') {
            padding: 20px;
        }

        @include mq('tablet') {
            padding: 20px 30px;
        }
    }

    &--bg-skewed {
        transform: skewX(-25deg);
    }

    &--bg-skewed &__wrapper {
        transform: skewX(25deg);
    }

    &__number {
        display: block;        
        @include fluid-type(400px, 768px, 40px, 46px);
    }

    &__time {
        display: block;
        font-size: 13px;
        margin-top: 8px;
        @include fluid-type(400px, 768px, 13px, 14px);

        &--sm {
            font-weight: 700;
            @include fluid-type(400px, 768px, 11px, 12px);
        }

        &--gray {
            color: color('gray-dark-2');
        }
    }
}