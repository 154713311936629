.heading-1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;

    @include mq('tablet') {
        font-size: 40px;
    }
}

.heading-2 {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 700;

    @include mq('tablet') {
        font-size: 30px;
    }

    &--primary {
        color: color('primary');
    }

    &--line-left {
        padding-left: 15px;
        position: relative;

        @include mq('tablet') {
            padding-left: 20px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 9px;
            left: 0;
            height: 15px;
            width: 3px;
            background: color('primary');

            @include mq('tablet') {
                height: 23px;
                width: 4px;
                top: 14px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            height: 3px;
            width: 3px;
            background: color('red');
            z-index: 5;

            @include mq('tablet') {
                top: 6px;
                height: 4px;
                width: 4px;
            }
        }
    }
}

.heading-3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    color: color('gray-dark-2');

    @include mq('tablet') {
        font-size: 24px;
    }

    &--square {
        padding-left: 30px;
        position: relative;

        @include mq('tablet') {
            padding-left: 35px;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: color('red');

            @include mq('tablet') {
                top: 15px;
                width: 10px;
                height: 10px;
            }
        }

        &:before {
            background: color('primary');
        }

        &:after {
            background: color('red');
            top: 18px;
            left: 10px;

            @include mq('tablet') {
                top: 25px;
            }
        }
    }
}

.heading-4 {
    font-size: 15px;
    font-weight: 300;

    @include mq('tablet') {
        font-size: 21px;
    }
}

.heading-5 {
    font-size: 15px;
    font-weight: 700;

    @include mq('tablet') {
        font-size: 18px;
    }
}

.heading-6 {
    font-size: 14px;
    font-weight: 700;

    @include mq('tablet') {
        font-size: 16px;
    }
}

.text-xl {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.5;

    @include mq('tablet') {
        font-size: 50px;
    }
}

.text-base {
    font-size: 14px;
    font-weight: 500;

    @include mq('tablet') {
        font-size: 16px;
    }
}

.text-small {
    font-size: 12px;
    font-weight: 500;

    @include mq('tablet') {
        font-size: 14px;
    }
}

.text-blue {
    color: color('primary');
}

.text-gray {
    color: color('gray-dark-1');
}

.font-bold {
    font-weight: 700;
}

.font-medium {
    font-weight: 500;
}

.text-normal {
    font-weight: 300;
}

.font-21 {
    font-size: 18px;
    @include mq('tablet') {
        font-size: 21px;
    }
}