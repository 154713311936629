
//#SERVICE
.services {
    margin: 30px 0 0 0;

    @include mq('phablet') {
        margin: 50px 0 0 0;
    }

    @include mq('tablet') {
        margin: 100px 0 0 0;
    }
}

// service-card
.service-card {
    margin-bottom: 50px;

    @include mq('tablet') {
        margin-bottom: 100px;
    }

    &__title {
        margin: 15px 0;
        line-height: 1.5;
        position: relative;

        @include mq('tablet') {
            margin: 25px 0;
        }

        &--ls-4 {
            letter-spacing: -4px;
        }
    }

    &__desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 40px;

        @include mq('tablet') {
            font-size: 18px;
        }
    }
}