.sub-nav {
    background: linear-gradient(to bottom, color('primary-lightest'), color('white'));
    padding: 15px 0;

    @include mq('tablet') {
        margin-top: 10px;
        padding: 20px 0;
    }

    &__wrapper {
        text-align: center;

        @include mq('tablet') {
            text-align: left;
        }
    }

    &__flex-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include mq('tablet') {
            padding-right: 420px;
        }

        @include mq('bootstrap-lg') {
            flex-direction: row;
            padding-right: 390px;
        }

        &--content-start {
            justify-content: flex-start;
        }
    }

    &__title {
        color: color('primary');
        word-break: keep-all;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;

        @include mq('phone') {
            font-size: 24px;
        }

        @include mq('tablet') {
            font-size: 40px;
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-weight: 500;
        font-size: 14px;

        @include mq('phablet') {
            justify-content: center;
        }

        @include mq('bootstrap-lg') {
            margin-left: 30px;
            justify-content: flex-start;
        }

        &--w-100 {
            width: 100%;

            @include mq('phablet') {
              width: auto;
            }

        }
    }

    &__item {
        margin: 0 10px 10px 0;

        @include mq('tablet') {
            margin: 0 10px 10px 0;
        }

        &:last-child {
            @include mq('tablet') {
                margin-right: 0;
            }
        }
    }

    &__link {

        &,
        &:link,
        &:visited {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            background: color('white');
            color: color('primary');
            padding: 0 10px;
            text-align: center;
            border-radius: 20px;
            box-shadow: 0 4px 0 -1px color('primary-lightest');
            text-decoration: none;
            transition: all 0.2s;
        }

        &:hover,
        &.is-active {
            background: color('primary');
            color: color('white');
        }

        &:active {
            box-shadow: 0 2px 0 -1px color('primary-lightest');
        }
    }
}