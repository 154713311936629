.big-heading {
    margin-top: 80px;
    margin-bottom: 20px;

    @include mq('tablet', 'max') {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}


.recruit {
    &-lists {
        @include mq('tablet') {
            margin-bottom: 8rem;
        }

        margin-bottom: 4rem;
    }

    &-list {
        padding: 5px 0;
        margin: 0 auto;
        max-width: 970px;
        border-bottom: 1px solid color('gray');

        @include mq('tablet') {
            padding: 8px 0;
        }

        &:first-child {
            border-top: 1px solid color('gray');
        }

        &__container {
            margin-bottom: 4rem;
            padding-top: 20px;

            @include mq('tablet') {
                padding-top: 30px;
            }
        }

        &__left,
        &__right {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 5px;
        }

        &__left {
            margin-bottom: 5px;

            @include mq('tablet') {
                flex: 0 0 145px;
                max-width: 0 0 145px;
                margin-bottom: 0;
            }

            font-weight: bold;
        }

        &__right {
            font-size: 14px;

            @include mq('tablet') {
                flex: 0 0 calc(100% - 145px);
                max-width: 0 0 calc(100% - 145px);
                font-size: 16px;
            }
        }
    }
}

.list-disc {
    li {
        position: relative;
        padding-left: 15px;

        &:before {
            top: 11px;
            left: 5px;
            width: 3px;
            content: '';
            height: 3px;
            background: #000;
            position: absolute;
            border-radius: 50%;
        }
    }
}