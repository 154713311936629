.btn {

    &,
    &:link,
    &:visited {
        background: color('primary');
        display: block;
        margin: 0 auto;
        font-weight: 700;
        padding: 0;
        color: color('white');
        border-radius: 30px;
        box-shadow: 0px 6px 0 -1px color('black');
        transition: all 0.2s ease-out;
    }

    &:hover {
        background: rgba(color('primary'), 0.75);
        box-shadow: 0px 6px 0 -1px rgba(color('black'), 0.75);
        color: color('white');
    }

    &:active {
        box-shadow: 0px 3px 0 -1px rgba(color('black'), 0.75);
    }

    &--primary-light {

        &,
        &:link,
        &:visited {
            background: color('primary-lightest');
            color: color('primary');
        }

        &:hover {
            background: rgba(color('primary-lightest'), 0.75);
            color: color('primary');
        }

    }

    &--sm {
        width: 100%;
        height: 30px;
        line-height: 30px;
        font-size: 14px;

        @include mq('phablet') {
            width: 85%;
            height: 40px;
            line-height: 40px;
        }

        @include mq('bootstrap-lg') {
            width: 270px;
        }
    }

    &--lg {

        &,
        &:link,
        &:visited {
            width: 100%;
            font-size: 21px;
            text-align: center;
            border-radius: 10px;
            padding: 12px 10px;

            @include mq('phablet') {
                font-size: 24px;
                width: 470px;
                height: 50px;
                line-height: 50px;
                padding: 0;
                border-radius: 30px;
            }
        }
    }

    &-red {
        width: 100%;
        max-width: 470px;
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 30px;
        display: inline-block;
        color: color('white');
        font-size: 21px !important;
        margin-top: 3.125rem !important;
        background-color: color('red') !important;
        box-shadow: 0 5px 0 0 color('red-dark') !important;

        @include mq('tablet') {
            width: 470px;
            line-height: 48px;
            font-size: 24px !important;
        }

        &:hover {
            color: color('white');
            background-color: rgba($color: color('red'), $alpha: .75) !important;
            box-shadow: 0 5px 0 0 rgba($color: color('red-dark'), $alpha: .75) !important;
        }

        &:focus,
        &:active {
            outline: 0;
            background-color: color('red') !important;
            box-shadow: 0 2px 0 0 color('red-dark') !important;
        }
    }
}


.main-btn {
    margin-bottom: 50px;

    @include mq('tablet') {
        margin-bottom: 70px;

    }

    &__wrapper {
        border-top: 1px solid color('gray');
        padding-top: 50px;
    }
}