.notice-details {
    margin: 20px 0 30px 0;
    font-weight: 500;
    padding-bottom: 50px;
    border-bottom: 1px solid color('gray');

    @include mq('tablet') {
        margin: 80px 0 100px 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

    &__title {
        text-align: center;
        color: color('primary');
        margin: 0;
    }

    &__date {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0 15px;
        font-size: 14px;

        @include mq('tablet') {
            margin: 30px 0 25px;
            font-size: 16px;
        }
    }

    &__desc {
        margin-top: 25px;
    }

}

.notice-details-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid color('gray');

    @include mq('tablet') {
        margin-top: 50px;
    }
}

.notice-details-btn {
    &__control {

        &,
        &:link,
        &:visited {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            font-size: 14px;
            font-weight: 300;
            color: color('primary');

            @include mq('tablet') {
                font-size: 16px;
            }
        }

        &:hover {
            text-decoration: underline;
        }

        img {
            width: 15px;

            @include mq('tablet') {
                width: 20px;
            }
        }

        &--left {
            img {
                margin-right: 8px;

                @include mq('tablet') {
                    margin-right: 10px;
                }
            }
        }

        &--right {
            img {
                margin-left: 8px;

                @include mq('tablet') {
                    margin-left: 10px;
                }
            }

        }
    }

    &__back {

        &,
        &:link,
        &:visited {
            height: 24px;
            line-height: 24px;
            padding: 0 12px;
            box-shadow: 0 4px 0 -1px rgba(color('black'), 0.75);
            font-weight: 500;
            font-size: 12px;

            @include mq('tablet') {
                font-size: 14px;
                height: 30px;
                line-height: 30px;
                padding: 0 20px;
            }
        }

        &:hover {
            background: rgba(color('primary'), 0.75);
            box-shadow: 0px 4px 0 -1px rgba(color('black'), 0.75);
            color: color('white');
        }

        &:active {
            box-shadow: 0px 3px 0 -1px rgba(color('black'), 0.75);
        }
    }
}