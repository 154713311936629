.arrow {
    width: 15px;
    height: auto;

    &-right {
        margin-left: .5rem;
    }
}
.recruit {
    &-lists {

        @include mq('tablet') {
            margin-bottom: 8rem;
            font-size: 16px;
        }

        margin-bottom: 4rem;

        img {
            box-shadow: 0 11px 0 -6px color('gray');
        }

        p {
            font-size: 14px;

            @include mq('tablet') {
                font-size: 16px;
            }
        }
    }

    &-content {
        @include mq('tablet') {
            margin-bottom: 4rem;
        }

        margin-bottom: 1.8rem;
        padding-top: 30px;
    }
}