@include mq('tablet') {
    .wrapper {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        background-attachment: fixed;
        transition: all 0.5s ease-in;
        padding-top: 20px;
    }

    #wrapper--bg-1 {
        background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'),0.3)), url(../img/hero-1.jpg);
    }

    #wrapper--bg-2 {
        background-image: linear-gradient(rgba(color('primary-dark-1'), 0.3), rgba(color('primary-dark-1'),0.3)),  url(../img/hero-2.jpg);
    }
}