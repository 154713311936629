.footer {
    color: color('white');
    font-size: 12px;

    @include mq('tablet') {
        background: color('primary');
        padding: 18px 0;
    }

    &--bg {

        @include mq('tablet') {
            background: rgba(color('primary'), 0.8);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 -15px;

        @include mq('tablet') {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin: 0;
        }
    }

    &__privacy-policy {
        padding: 30px 15px 15px;

        @include mq('tablet') {
            padding: 0;
            color: inherit;
            text-align: left;
        }

        a {
            &,
            &:link,
            &:visited {
                color: color('gray-dark-2');
                text-decoration: none;

                @include mq('tablet') {
                    color: inherit;
                    text-align: left;
                }
            }

            &:hover {
                font-weight: 700;
            }
        }
    }

    &__copyright {
        background: color('primary');
        font-weight: 500;
        padding: 15px 0;
        text-align: center;
        width: 100%;

        @include mq('tablet') {
            padding: 0;
            background: transparent;
            width: 100%;
            text-align: left;
            width: auto;
        }
    }
}