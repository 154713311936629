.heading-2--line-left.mb-4-5 {
    margin-bottom: 15px;
    @include mq('tablet') {
        margin-bottom: 30px;
    }
}
.section {

    &-facilities {
        @include mq('tablet-small') {
            padding-top: 5rem;
        }
        padding-top: 3rem;

        &:nth-child(2) {
            @include mq('tablet-small') {
                padding-top: 6rem;
            }
        }
        &:last-child {
            padding-bottom: 3.7rem;
            @include mq('tablet-small') {
                padding-bottom: 10rem;
            }
        }
        iframe {
            border: 0;
            width: 100%;
            background-color: #efefef;
        }

        &__item {
            &-content {
                padding-block-end: 2rem;

                .facilities-img {
                    position: relative;
                    img {
                        @include mq('tablet-small') {
                            right: 0;
                            max-width: 370px;
                            position: absolute;
                            bottom: -60px;
                        }
                        box-shadow: 0 10px 0 -5px #ccc;
                    }
                }
            }
        }
        
        &__details {
            padding-bottom: .4rem;
            font-size: 14px;
            font-weight: 500;

            @include mq('tablet') {
                font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
                font-size: 16px;
            }
            &-left {
                width: 80px;
            }
        }
    }
}