
.company__slogan
{
	font-size: 50px;
	color: color('primary');
	margin: 100px auto 72px;
	font-weight: 500;
}
.first__row
{
	display: flex;
	margin-bottom: 75px;
	& a:hover
	{
		text-decoration: none;
	}
	& .left__column
	{
	    display: flex;
	    flex-direction: column;
	    flex:0 0 50%;
		& .profile__heading
		{
			font-weight: 700;
			margin-bottom: 30px;
		}
		& .profile__content
		{
			height: 100%;
		    display: flex;
		    justify-content: space-between;
		    flex-direction: column;
			& .contents__listing
			{
				& .main-list
				{
					border-top: 1px solid color('gray');
					padding: 10px 40px 5px 0px;
				}
				& .demo-head
				{
					font-weight: 700;
					flex:0 0 50%;
					margin-bottom: 5px;

					@include mq('tablet') {
						margin-bottom: 0;
					}
				}
				& .for-sub-list
				{
					border-bottom: 1px solid color('gray');
					padding: 0px;
					& p
					{
						flex: 0 0 46%;
					}
					& li 
					{
					    padding: 5px 67px 5px 0px;
					    margin-left: 0px;
					    flex-basis:54%;
					    & p
					    {
					    	flex-basis:100%;
					    }
					}
					& li:last-child
					{
						border-top: 1px solid color('gray');
					}
				}
				& li
				{
					display: flex;
					align-items:center;
					margin-left: 75px;
				}
			}

		}
	}
	& .right__column
	{display: flex;
	    flex-direction: column;
		& .message__from__ceo
		{			height: 100%;
		    display: flex;
		    justify-content: space-between;
		    flex-direction: column;
			& .message__heading
			{
				font-weight: 700;
			}
			& .ceo__message
			{
				margin: 30px 0px 0px 100px;
				& .image__of__ceo
				{
					margin-bottom: 20px;
					& img
					{
						width: 270px;
					}
				}
				& .name__of__ceo
				{
					margin-bottom: 15px;
				}
			}
		}
	}
}

.branches__locations
{
    display: flex;
    flex-wrap: wrap;
    margin:15px 0px 80px 90px;
    & .office
    {
    	flex: 0 0 50%;
    	max-width: 50%;
    	margin-bottom: 30px;
    	& h3
    	{
    		font-weight: 500;
    	}
    	& .map__and__locations
    	{
    		display: flex;
    		margin-top: 20px;
    		align-items:flex-start;
    		& p
    		{
    			margin-bottom: 20px;
				font-weight: 500;
				font-size: 14px;

				@include mq('tablet') {
					font-size: 16px;
				}
    		}
    		& button
    		{
    			width: 89px;
    			height: 30px;
    			margin-left: 10px;
    			padding: 0px;
    			font-size: 14px;
    			line-height: 1;
    			& svg
    			{
    				width: 16px;
    				fill: color('white');
    				margin-right: 5px;
    			}
    		}
    	}
    }
}
.major__bank__and__license
{
	display: flex;
	justify-content:space-between;
	margin-bottom: 50px;
	& .major__bank__list
	{
		flex:0 0 50%;
		max-width: 50%;
	}
	& ul
	{
		margin-left: 100px;
		margin-top: 30px;
		& li
		{
			font-weight: 500;
			font-size: 14px;

			@include mq('tablet') {
				font-size: 18px;
			}
		}
	}
}
.business_support__list
{
	margin-bottom: 150px;
	& ul
	{
	    display: flex;
    	flex-wrap: wrap;
	    margin-left: 100px;
	    margin-top: 30px;
	    & li
	    {
	    	font-weight: 500;
		    font-size: 14px;
		    flex-basis: 50%;
			max-width: 50%;
			
			@include mq('tablet') {
				font-size: 16px;
			}
	    }
	}
}
#before__footer__for__mobile
{
	display: none;
}
@include mq('tablet','max')
{
	.company__slogan
	{
		font-size: 30px;
		margin: 30px auto 45px;
	}
	.first__row
	{
		display: block;
		& .left__column
		{
			& .profile__heading
			{
				margin-bottom: 15px;
			}
			& .profile__content
			{
				margin-bottom: 50px;
				& .contents__listing
				{
					margin-bottom: 30px;
					& .main-list
					{
						display: block;
						margin-left: 0px;
					}
					& .for-sub-list
					{
						& li 
						{
						    margin-left: 0px;
						    padding: 0px;
						}
						& li:last-child
						{
							border-top: none;
						}
					}
				}
			}
		}
		& .right__column
		{
			display: block;
			& .message__from__ceo
			{
				& .ceo__message
				{
					margin: 20px 0px 0px 0px;
				}
			}
		}
	}
	.branches__locations
	{
	    display: block;
	    margin: 20px 0px 50px 0px;
	    & .office
	    {
	    	max-width: 100%;
	    	& .map__and__locations
	    	{
	    		justify-content:space-between;
	    		& button
	    		{
	    			width: 70px;
	    			height: 24px;
	    			font-size: 12px;
	    			font-weight: 500;
	    			& svg
	    			{
	    				width: 14px;
		    		}
		    	}
	    	}
	    }
	}
	.major__bank__and__license
	{
		display: block;
		& .major__license__list
		{
			& ul
			{
				margin: 15px 0px 50px;
			}
		}
		& .major__bank__list
		{
			max-width: 100%;
			& ul
			{
				margin: 15px 0px 60px;
			}
		}
	}
	.business_support__list
	{
		margin-bottom: 60px;
		& ul
		{
			display: block;
			margin-left: 0px;
			margin-top: 15px;
			& li
			{
				max-width: 100%;
			}
		}
	}
	#before__footer__for__mobile
	{
		display: block;
		margin-bottom: 60px;
	}
}
