#for__inquiry {
	margin-top: 100px;

	& hr {
		margin: 80px auto 50px;
	}
}

.form__info {
	margin: 31px auto;
	font-weight: 500;
}

.inquiry__head {
	display: flex;
	align-items: center;
	color: color('black');

	& h2 {
		font-weight: 700;
	}

	& img {
		height: 30px;
		margin-right: 18px;
		font-weight: 700;
	}
}

.inquiry__number .contact__time {

	@include mq('tablet') {
		font-size: 16px;
	}
}

.inquiry__number .contact__time--sm {

	@include mq('tablet') {
		font-size: 16px;
	}
}


.to__enter__details {
	display: flex;
	justify-content: center;

	& .fill__up__below {
		display: flex;
		flex-direction: column;
		justify-content: center;

		& .form__details {
			width: 100%;
			padding-top: 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-weight: 500;

			label {
				min-width: 150px;

				@include mq('phablet') {
					margin-bottom: 0;
				}
			}

			& .input__here {
				width: 80%;

				& input {
					width: 200px;
					height: 40px;
					border-radius: 4px;
					border: 1px solid #DDDDDD;
					padding: 10px;
				}

				& input[type="email"] {
					width: 100%;

					@include mq('phone') {
						width: 300px;
					}
				}
			}

			& textarea {
				width: 770px;
				height: 119px;
				border: 1px solid #DDDDDD;
				border-radius: 4px;
				resize: none;
				padding: 10px;
			}
		}
	}

	& .after__filling__up {
		display: flex;
		justify-content: center;
		margin: 30px auto 50px !important;

		@include mq('tablet') {
			margin: 60px auto 150px;
		}
		

		.btn {
			width: 120px;
			margin: 0 10px;

			@include mq('phablet') {
				width: 170px;
				font-size: 16px;
			}
		}
	}
}

@include mq('phablet', 'max') {
	.form__details {
		flex-direction: column;
		align-items: normal !important;
		padding-top: 20px !important;

		& textarea {
			width: 100% !important;
			height: 160px !important;
		}

		& .input__here {
			width: 100% !important;
		}
	}
}

@include mq('bootstrap-lg', 'max') {
	.to__enter__details {
		flex-direction: column;
		border-bottom: 1px solid color('gray');
		margin-bottom: 10px;
	}

	#for__inquiry {
		margin-top: 30px;

		& hr {
			margin: 30px auto 75px;
		}
	}

	.form__info {
		margin-bottom: 25px;
		font-weight: 500;
	}
}

@include mq('tablet', 'max') {
	.to__enter__details {
		border-bottom: 1px solid color('gray');
		margin-bottom: 20px;
	}
}

@include mq('phone-md') {

	input,
	input[type="email"],
	textarea {
		max-width: 100%;
	}
}

.section-inquiry {
	background-image: url('../img/pc-inquiry-header@2x.jpg');
}