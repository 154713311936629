#companies__timeline {
	margin-bottom: 30px;

	@include mq('phablet') {
		margin-bottom: 80px;
	}

	@include mq('tablet') {
		margin-bottom: 160px;
	}

	& .date__on__history {
		display: flex;
		margin: 25px 0 0;
		flex-direction: column;
		padding-bottom: 50px;
		border-bottom: 1px solid color('gray');

		@include mq('phablet') {
			align-items: flex-start;
			margin: 94px auto 0;
			flex-direction: row;
			padding-bottom: 0;
			border-bottom: 0;
		}

		@include mq('bootstrap-lg') {
			width: 80%;
		}

		& .years__sidebar {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 0 -10px 35px;

			@include mq('phablet') {
				display: block;
				flex: 0 1 140px;
				margin: 0;
			}

			@include mq('bootstrap-lg') {
				flex: 0 1 170px;
			}

			& a {
				display: inline-block;
				color: color('primary');
				font-size: 16px;
				font-weight: 500;
				padding: 0 10px;
				margin-bottom: 8px;

				@include mq('phablet') {
					display: block;
					font-size: 18px;
					margin-bottom: 30px;
					padding: 0;
				}
			}

			& a:hover {
				text-decoration: none;
			}
		}

		& .history__content {

			@include mq('phablet') {
				border-left: 1px solid color('gray');
				flex: 0 0 calc(100% - 140px);
			}

			@include mq('bootstrap-lg') {
				flex: 0 0 calc(100% - 170px);
			}

			& .timeline {
				margin-bottom: 30px;

				@include mq('phablet') {
					margin-left: 25px;
					margin-bottom: 50px;
				}

				& .date__heading {
					border-bottom: 1px solid color('primary');
					padding-bottom: 3px;
					text-align: center;

					@include mq('phablet') {
						padding-bottom: 10px;
					}

					& h4 {
						font-weight: 700;
						font-size: 16px;
						margin: 0;

						@include mq('phablet') {
							font-size: 21px;
						}
					}
				}

				& .timeline__list {
					& .table__list {
						display: flex;
						flex-direction: column;
						border-bottom: 1px solid color('gray');
						padding: 7px 0px;

						@include mq('phablet') {
							padding: 12px 0px;
						}

						@include mq('tablet') {
							flex-direction: row;
							align-items: center;
						}

						& p {
							font-size: 14px;

							@include mq('phablet') {
								font-size: 16px;
							}
						}

						& .history__head {
							font-weight: 700;
							margin-bottom: 5px;

							@include mq('phablet') {
								padding-bottom: 10px;
								margin-bottom: 0;
							}

							@include mq('tablet') {
								flex: 0 0 160px;
							}
						}

						& span {
							color: color('red');
						}
					}

				}
			}

			.during__heisei-present {
				margin-bottom: 0px;
			}
		}
	}
}

// @include mq('bootstrap-lg','max')
// {
// 	#companies__timeline
// 	{
// 		margin-bottom: 50px;
// 		& .date__on__history
// 		{
// 			margin-top: 20px;
// 			flex-wrap:wrap;
// 			& .years__sidebar
// 			{
// 				flex-basis:100%;
// 				justify-content:center;
// 				& a
// 				{
// 					padding:0px 15px 0px 0px;
// 				}
// 			}
// 			& .history__content
// 			{
// 				border: none;
// 				flex-basis:100%;
// 				margin-top: 37px;
// 				& .timeline
// 				{
// 					margin-left: 0px;
// 					& .timeline__list
// 					{
// 						& .table__list
// 						{
// 							display: block;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
// @include mq('tablet','max')
// {
// 	h4
// 	{
// 		font-size: 16px;
// 	}
// }