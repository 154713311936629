@font-face {
    font-family: 'source-hans-sans-jp';
    src: url('../fonts/SourceHanSansJP-Normal.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'source-hans-sans-jp';
    src: url('../fonts/SourceHanSansJP-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'source-hans-sans-jp';
    src: url('../fonts/SourceHanSansJP-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'source-hans-sans-jp';
    src: url('../fonts/SourceHanSansJP-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}